/* General Button Style */

.btn{
  font-weight: 700 !important;
  letter-spacing: 0.8px;
  color: #fff !important;
  background-color: transparent !important;
  border: 2px solid white !important;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative !important;
  transition: 0.3s ease-in-out;
}

.btn span {
  z-index: 1 !important;
  position: relative !important;
}

.btn:hover {
  color: #121212 !important;
}

.btn::before {
  content: "";
  background: #fff ;
  width: 0 ;
  height: 100% ;
  position: absolute ;
  top: 0;
  left: 0;
  z-index: 0 ; 
  transition: 0.3s ease-in-out;
}

.btn:hover::before {
  width: 100% ;
}



.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  width: "40%";
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;

}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

@media only screen and (max-width: 960px) {
  body {
    background-color: lightblue;
  }
}